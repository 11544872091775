import {
  LoginForm,
  LoginFormCopy,
  ConfirmEmail,
  ConfirmEmailCopy,
  SlideOverDialog,
  LoggedInView,
  LoggedInViewCopy,
  useSlideOverDialogContext,
} from "@bluebottlecoffee/design-system/components";
import { useUser, useAuth, useAnalytics } from "@chordcommerce/react-autonomy";
import Script from "next/script";
import { FunctionComponent, useEffect, useState } from "react";
import emailIsValid from "../lib/utils/strings/email-is-valid";
import { AccountMenuCopy, accountMenuLinks } from "../lib/account-menu";

export interface DialogData {
  copy: LoginFormCopy;
  accountMenuCopy: AccountMenuCopy;
  region: string;
  lang: string;
}

export const LoginDialog: FunctionComponent<DialogData> = ({
  copy,
  accountMenuCopy,
  ...defaultProps
}) => {
  const { lang, region } = defaultProps;
  const { activeContentType } = useSlideOverDialogContext();
  const { user, loadUser } = useUser();
  const { logout, isLoggedIn, getToken } = useAuth();
  const [email, setEmail] = useState<string>("");
  const [emailSubmitted, setEmailSubmitted] = useState<boolean>(false);
  const confirmEmailCopy: ConfirmEmailCopy = {
    confirmModalDismiss: copy.confirmModalDismiss,
    confirmModalTitle: copy.confirmModalTitle,
    confirmModalEmailConfirmation: copy.confirmModalEmailConfirmation,
    confirmModalInstructions: copy.confirmModalInstructions,
  };
  const loggedInCopy: LoggedInViewCopy = {
    loggedInGreeting: copy.loggedInGreeting,
    accountMenuHeading: copy.accountMenuHeading,
    logoutButton: copy.logoutButton,
  };
  const dialogTitle: string = isLoggedIn ? accountMenuCopy.account : copy.title;

  useEffect(() => {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getToken().catch((_err) => {});
  }, []);

  useEffect(() => {
    if (activeContentType === "login" && isLoggedIn) {
      loadUser();
    }
  }, [isLoggedIn, activeContentType]);

  const { trackEmailCaptured } = useAnalytics();

  type submitResponse = { success: boolean; message: string };

  const onSubmit = async (email): Promise<submitResponse> => {
    const errorStatus = (message?: string): submitResponse => ({
      success: false,
      message: `${copy.unableToLogInMessage}${message ? `: ${message}` : ""}`,
    });

    if (!emailIsValid(email)) {
      return Promise.resolve({
        success: false,
        message: copy.invalidEmailFormat,
      });
    }

    setEmail(email);
    trackEmailCaptured({
      emailCapture: {
        email,
      },
    });
    try {
      return new Promise((resolve) => {
        window.grecaptcha.ready(async () => {
          const token = await window.grecaptcha.execute(
            process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY,
            {
              action: "login",
            },
          );

          const base = process.env.NEXT_PUBLIC_CLOUDFLARE_WORKER_API_HOST || "";

          const redirectUri = window.location.href;

          const loginResponse = await fetch(`${base}/api/v1/login`, {
            method: "POST",
            body: JSON.stringify({
              email,
              redirectUri,
              token,
            }),
          });

          const { message: errorMessage } = await loginResponse.json();

          if (loginResponse.status === 200) {
            setEmailSubmitted(true);
          }

          resolve(errorStatus(errorMessage));
        });
      });
    } catch (error) {
      return errorStatus(error);
    }
  };

  const handleLogout = async () => {
    logout();
  };

  const renderDialogContent = () => {
    if (email && emailSubmitted) {
      return <ConfirmEmail copy={confirmEmailCopy} email={email} />;
    }

    if (!isLoggedIn) {
      return (
        <>
          <LoginForm
            copy={copy}
            awaitingLinkClick={false}
            onSubmit={onSubmit}
          />
          <Script
            id="google-recaptcha"
            src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY}`}
          />
        </>
      );
    }

    return (
      <LoggedInView
        copy={loggedInCopy}
        menuLinks={accountMenuLinks(accountMenuCopy, region, lang)}
        onLogOut={handleLogout}
        user={user.data}
      />
    );
  };

  return (
    <SlideOverDialog
      dismissText={copy.dismissText}
      title={dialogTitle}
      contentType="login"
    >
      {renderDialogContent()}
    </SlideOverDialog>
  );
};

import { toHTML } from "@portabletext/to-html";
import { AnnouncementBarProps } from "@bluebottlecoffee/design-system/components";
import { AnnouncementBar as Schema } from "../sanity-schema";

function validate(data: Schema, locale: string) {
  if (!data) {
    throw new Error("No data provided");
  } else if (!data.announcements || data.announcements.length === 0) {
    throw new Error("No announcements provided");
  } else if (data.announcements.filter((a) => a[locale]).length === 0) {
    throw new Error(`No ${locale} announcements provided`);
  }
}

export function toAnnouncementBarProps(
  data: Schema,
  lang: string,
): AnnouncementBarProps {
  validate(data, lang);
  return {
    announcements: data.announcements
      .filter((a) => a[lang])
      .map((a) => ({ announcement: toHTML(a[lang]) })),
    ...(data.barColor && { barColor: data.barColor }),
  };
}

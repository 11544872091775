import { toHTML } from "@portabletext/to-html";
import { DialogProps } from "@bluebottlecoffee/design-system/components";
import { CookiesNotice as Schema } from "../sanity-schema";

type CookiesNoticeTransformerProps = {
  data: Schema;
  lang: string;
};

type DescriptionAddendumTransformerProps = {
  data: Schema["descriptionAddendum"];
  lang: string;
};

export type DialogTransformerProps = Omit<
  DialogProps,
  "isOpen" | "onSubmit" | "descriptionAddendum"
> & {
  descriptionAddendum: Omit<DialogProps["descriptionAddendum"], "onClick">;
};

function validate(data: Schema, lang: string) {
  if (!data) {
    throw new Error("No Cookies Notice data provided");
  } else if (!data.description[lang]) {
    throw new Error("No Cookies Notice description provided");
  } else if (!data.dismiss[lang]) {
    throw new Error("No Cookies Notice dismiss text provided");
  }
}

export function toDescriptionAddendumProps({
  data,
  lang,
}: DescriptionAddendumTransformerProps): DialogTransformerProps["descriptionAddendum"] {
  return {
    ...(data.beginningText?.[lang] && {
      beginningText: data.beginningText[lang],
    }),
    clickableText: data.clickableText[lang],
    ...(data.endingText?.[lang] && { endingText: data.endingText[lang] }),
  };
}

export function toCookiesNoticeProps({
  data,
  lang,
}: CookiesNoticeTransformerProps): DialogTransformerProps {
  validate(data, lang);
  const { heading, description, dismiss } = data;
  return {
    heading: heading[lang],
    description: toHTML(description[lang]),
    ...(data.descriptionAddendum?.clickableText[lang] && {
      descriptionAddendum: toDescriptionAddendumProps({
        data: data.descriptionAddendum,
        lang,
      }),
    }),
    dismiss: dismiss[lang],
  };
}

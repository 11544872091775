/* eslint-disable no-console */
import { SanityClient } from "@sanity/client";
import { SearchIndex } from "algoliasearch";
import { DereferencedVariant } from "../../sanity/variant-queries";
import { AlgoliaTags } from "../types";

/**
 * Extract records from Sanity, optionally transform, and then push to Algolia
 *
 * Runs the provided query against Sanity and then indexes the records in Algolia.
 * If a transformation callback is provided, the records will be transformed prior to
 * indexing.
 *
 * @template SanityRecordT Sanity record type
 * @template AlgoliaRecordT Record type to be indexed; may be same as SanityRecordT if there is no transformation step
 *
 * @param {SanityClient} sanityClient Sanity client
 * @param {string} sanityQuery Sanity query string
 * @param {AlgoliaIndex} searchIndex Algolia search index to which transformed records should be pushed
 * @param {(records: SanityRecordT | SanityRecordT[]) => AlgoliaRecordT[]} transform Optional callback for transforming Sanity records before pushing to Algolia
 */
export function etl<SanityRecordT, AlgoliaRecordT>(
  sanityClient: SanityClient,
  sanityQuery: string,
  searchIndex: SearchIndex,
  transform: (record: SanityRecordT | SanityRecordT[]) => AlgoliaRecordT[],
) {
  return sanityClient
    .fetch<SanityRecordT | SanityRecordT[]>(sanityQuery)
    .then(transform)
    .then(searchIndex.replaceAllObjects)
    .then((r) =>
      console.log(
        `Replaced ${searchIndex.indexName} with ${r.objectIDs.length} objects:`,
        r,
      ),
    )
    .catch((e) => {
      console.log(e);
      throw Error(JSON.stringify(e));
    });
}

/**
 * Prepares Sanity DereferencedVariant data to be attached to an Algolia Product
 *
 * @param {DereferencedVariant[]} variants Records to transform
 *
 * @returns {DereferencedVariant[]} Transformed records
 */
export function transformVariants(
  variants: DereferencedVariant[],
): DereferencedVariant[] {
  return variants.map(
    ({
      _id,
      _rev,
      _type,
      _createdAt,
      _updatedAt,
      label,
      price,
      inventoryLimited,
      initialInventoryCount,
      isWaitListable,
      cartLimit,
      images,
      forSale,
      assortment,
      deliveryMethods,
      optionValues,
      regularPrice,
      sellingPlans,
      sku,
      gtin,
      totalPackageWeight,
      weight,
    }) => ({
      _id,
      _rev,
      _type,
      _createdAt,
      _updatedAt,
      label,
      price,
      inventoryLimited,
      initialInventoryCount,
      isWaitListable,
      cartLimit,
      images,
      forSale,
      assortment,
      deliveryMethods,
      optionValues,
      regularPrice,
      sellingPlans,
      sku,
      gtin,
      totalPackageWeight,
      weight,
    }),
  );
}

/**
 * Reduces an array of `Tag`s to an object in which the properties are tag prefixes and
 * the values are an array of suffixes. Effectively ignores tags that do not conform to
 * Blue Bottle's `"prefix":"suffix"` convention.
 *
 * @example
 * ```ts
 * const tags = ["process:washed", "process:natural", "roast-level:dark"];
 * const obj = reduceTagsToProps(tags);
 *
 * console.log(obj);
 * // {process: ["washed", "natural"], roast-level: ["dark"]}
 * ```
 *
 * @param {string[]} tags An array of `Tag`s
 *
 * @returns {{[key: string]: string[]}} An object in which the properties are tag prefixes and the values are an array of suffixes
 */
export function reduceTags(tags: string[]): AlgoliaTags {
  if (!tags) return {};

  return tags.reduce((result, tag) => {
    const [tagName, tagValue] = tag.split(":");

    if (!tagName || !tagValue) return result;

    if (result[tagName]) {
      result[tagName].push(tagValue);
    } else {
      Object.assign(result, { [tagName]: [tagValue] });
    }

    return result;
  }, {});
}

export function findBestValueVariant(
  productName: string,
  variants: DereferencedVariant[],
): DereferencedVariant {
  if (!variants) {
    console.error(`No variants were present on product: ${productName}`);
  }

  if (variants.length === 1) return variants[0];

  return variants.reduce(
    (prev, current) =>
      prev.regularPrice < current.regularPrice ? prev : current,
    variants[0],
  );
}

type Experiment<T> = {
  id: string;
  [key: number]: T;
};

type Experiments = {
  sortBy: Experiment<boolean>;
  onlySideCart: Experiment<boolean>;
  freeShippingBadge: Experiment<boolean>;
};

export const experiments: Experiments = {
  sortBy: {
    id: "26",
    1: false,
    2: true,
  },
  onlySideCart: {
    id: "36",
    1: false,
    2: true,
  },
  freeShippingBadge: {
    id: "37",
    1: false,
    2: true,
  },
};

import { MetaTag as Schema } from "../sanity-schema";

export type MetaTagProps = {
  name: string;
  content: string;
};

export function toMetaTagProps(data: Schema, lang: string): MetaTagProps {
  return {
    name: data.name,
    content: data.content[lang],
  };
}

import { Variant as DesignSystemVariant } from "@bluebottlecoffee/design-system/components/lib/types";
import { DereferencedVariant } from "../sanity/variant-queries";
import { toImageResponsiveProps } from "./image";

export function toVariantProps(
  data: DereferencedVariant,
  lang: string,
): DesignSystemVariant {
  if (!data) throw new Error(`Bad variant passed into 'toVariantProps'`);

  return {
    ...(data.cartLimit && { cartLimit: data.cartLimit }),
    ...(data.initialInventoryCount && {
      initialInventoryCount: data.initialInventoryCount,
    }),
    price: { amount: data.price, currency: "USD" },
    images: data.images?.map((i) => toImageResponsiveProps(i, lang)) ?? [],
    regularPrice: { amount: data.regularPrice, currency: "USD" },
    sku: data.sku,
    optionValues: data.optionValues,
    // Variant comes through with id of `variant_1234`
    id: Number(data._id.split("_").pop()),
    inventoryLimited: !!data.inventoryLimited,
    isWaitListable: !!data.isWaitListable,
  };
}

import { SetStateAction } from "react";
import { OrderBig } from "@chordcommerce/react-autonomy";
import { recommendClient } from "../../algolia/clients";
import { isValidShopCardWithoutCollection } from "../../algolia/instant-search/components/search-results";
import { hasOptionValues } from "../../utils";
import { findBestValueVariant } from "../../algolia/etl/shared";
import { AlgoliaRecommendProduct } from "../../algolia/types";
import { SourceProductRec } from "../../transformers/product-recs";
import { Dialect } from "../locale";

export const algoliaProductRecsIndexName = `${process.env.NEXT_PUBLIC_SANITY_STUDIO_API_DATASET}-products`;

export const productRecsWithBestValueVariant = (
  productRecs: SourceProductRec[] | AlgoliaRecommendProduct[],
  lang: Dialect["lang"],
) =>
  productRecs.map((product) => ({
    ...product,
    bestValueVariant: findBestValueVariant(
      product.name[lang],
      product.variants,
    ),
  }));

function createRequestObjects(data: Partial<OrderBig>) {
  const requests = data.lineItems.map((lineItem) => ({
    indexName: algoliaProductRecsIndexName,
    objectID: lineItem.variant.slug,
    maxRecommendations: 15,
    queryParameters: {
      filters: "forSale:true",
    },
    // TODO: define more focused fallbackParameters.
    // Empty string matches every object in index.
    fallbackParameters: {
      query: "",
      filters: "forSale:true",
    },
  }));
  return requests;
}

function isProductRecDataValid(
  product: AlgoliaRecommendProduct,
  lang: string,
): boolean {
  const valid: boolean =
    product?.description[lang] &&
    product?.images?.length > 0 &&
    product?.name[lang] &&
    isValidShopCardWithoutCollection(product as any) &&
    product?.slug?.current &&
    hasOptionValues(product, lang) &&
    product?.variants
      .map(
        (variant) =>
          variant?.regularPrice &&
          variant?.price &&
          variant?.sku &&
          variant?.optionValues
            .map((value) => value?.presentation)
            .every((res) => res),
      )
      .every((res) => res) &&
    !!product?.bestValueVariant;

  return valid;
}

function removeDuplicateRecommendations(
  sortedAlgoliaProductRecs: AlgoliaRecommendProduct[],
  cart: Partial<OrderBig>,
  lang: string,
) {
  const slugsInCart = cart.lineItems.map((lineItem) => lineItem.variant.slug);

  const isUnique = (value, index, self) =>
    index === self.findIndex((t) => t.objectID === value.objectID);

  const isNotInCart = (value) => !slugsInCart.includes(value.slug.current);

  // identify and remove recommendations with identical objectIDs
  // first (highest scoring) instance of an objectID will always remain
  return sortedAlgoliaProductRecs
    .filter(
      (value, index, self) =>
        isUnique(value, index, self) &&
        isNotInCart(value) &&
        isProductRecDataValid(value, lang),
    )
    .slice(0, 3);
}

interface GetRecommendedProducts {
  cart: Partial<OrderBig>;
  lang: string;
  setProductRecs: (
    value: SetStateAction<SourceProductRec[] | AlgoliaRecommendProduct[]>,
  ) => void;
}

export async function getRecommendedProducts({
  cart,
  lang,
  setProductRecs,
}: GetRecommendedProducts) {
  await recommendClient
    .getRelatedProducts(createRequestObjects(cart))
    .then((res) => {
      const algoliaProductRecs = res.results.flatMap((result) => {
        // Hits from fallback query are not provided with a _score prop.
        // Set to 0 to allow them to work in sort function
        result.hits.forEach((rec: AlgoliaRecommendProduct) => {
          if (!rec._score) {
            // eslint-disable-next-line no-param-reassign
            rec._score = 0;
          }
        });

        return result.hits;
      }) as AlgoliaRecommendProduct[];

      // Sort Recommendations
      const sortedAlgoliaProductRecs = algoliaProductRecs.sort(
        (a, b) => b._score - a._score,
      );

      const uniqueAlgoliaProductRecs = removeDuplicateRecommendations(
        sortedAlgoliaProductRecs,
        cart,
        lang,
      );

      setProductRecs(uniqueAlgoliaProductRecs);
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error.message);
    });
}

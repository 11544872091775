export default (email: string): boolean => {
  const emptyStrings = (x) => x.trim().length > 0;

  if (typeof email !== "string") {
    return false;
  }

  const handleAndDomain = email.trim().split("@").filter(emptyStrings); // "@abc".split("@") => ['', 'abc']

  if (handleAndDomain.length < 2) {
    return false;
  }

  const domainAndTld = handleAndDomain[1]
    .trim()
    .split(".")
    .filter(emptyStrings);

  return domainAndTld.length >= 2;
};

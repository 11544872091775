import useSWR from "swr";
import { VariantPriceInput, useProduct } from "@chordcommerce/react-autonomy";

export default function useVariantPrice(input: VariantPriceInput): {
  discountPrice?: number;
} {
  const { getPrice } = useProduct();
  const { data } = useSWR([input, "get-price"], (input) => getPrice(input));

  return {
    discountPrice:
      data?.discountPrice && Number((data.discountPrice * 100).toFixed()),
  };
}

import { NavItemSchema } from "@bluebottlecoffee/design-system/components/Nav/components/NavItem";
import { BaseNavProps } from "../../components/LayoutWrapper";
import { ImageWithAltText, Nav, NavItem } from "../sanity-schema";
import { toLinkProps } from "./link";
import { cartPage } from "../link-builders";
import { toImageProps } from "./image";

export function toNavItemProps(
  data: NavItem,
  region: string,
  lang: string,
): NavItemSchema {
  return {
    name: data.label[lang],
    ...((data.link?.internal || data.link?.external) && {
      link: toLinkProps(data.link, region, lang),
    }),
    ...(data.navItems && {
      navItems: data.navItems.map((i) => toNavItemProps(i, region, lang)),
    }),
  };
}

export function toNavProps(
  isTransparent: boolean,
  cartIcon: {
    cartItemCount: number;
    cartLinkLabel: string;
  },
  nav: Nav,
  region: string,
  lang: string,
  altBBLogo?: ImageWithAltText,
): Omit<BaseNavProps, "ariaLabels" | "skipLinks"> {
  const cartLinkUrl = cartPage({ region, lang });
  const trialSubIcon = nav.trialSubscriptionIconLink && {
    icon:
      nav.trialSubscriptionIconLink.icon &&
      toImageProps(nav.trialSubscriptionIconLink.icon, lang),
    link: toLinkProps(nav.trialSubscriptionIconLink.link, region, lang),
  };

  return {
    ...(altBBLogo && { altBBLogo: toImageProps(altBBLogo, lang) }),
    cartItemCount: cartIcon.cartItemCount,
    cartLink: {
      text: cartIcon.cartLinkLabel,
      url: cartLinkUrl,
    },
    navItems: nav.navItems.map((i) => toNavItemProps(i, region, lang)),
    transparentMode: isTransparent && nav.transparentMode,
    trialSubIconLink: trialSubIcon,
  };
}

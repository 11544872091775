import { InfoBlockProps } from "@bluebottlecoffee/design-system/components";
import { NavAndCartInfoBanner as Schema } from "../sanity-schema";

type NavAndCartInfoBannerTransformerProps = {
  data: Schema;
  lang: string;
};

function validate(data: Schema, lang: string) {
  if (data.showInfoBanners && !data.text[lang]) {
    throw new Error(
      "The Nav and Cart Info Banner feature has been enabled but no text has been provided.",
    );
  }
}

export function toNavAndCartInfoBannerProps({
  data,
  lang,
}: NavAndCartInfoBannerTransformerProps): InfoBlockProps {
  validate(data, lang);
  return {
    ...(data.bgColor && { bgColor: data.bgColor }),
    ...(data.borderColor && { borderColor: data.borderColor }),
    text: data.text[lang],
    dismiss: data.dismissAriaLabel[lang],
  };
}

import { ShopCardProps } from "@bluebottlecoffee/design-system/components";
import { Product as ProductChord } from "@chordcommerce/react-autonomy";
import useVariantPrice from "../chord/hooks/components/use-variant-price";
import { publicShopCollectionProductsIndex } from "../algolia/indices";
import { Dialect } from "../utils/locale";
import { AlgoliaRecommendProduct } from "../algolia/types";
import {
  OptionType,
  Product,
  ProductSubscriptionOption,
  QuantityLabel,
  ShopCollection,
  Subscription,
  SubscriptionInterval,
  Variant,
} from "../sanity-schema";
import { toShopCardProps } from "./shop-card";
import { DereferencedShopCard } from "../sanity/shop-card-queries";
import { DereferencedVariant } from "../sanity/variant-queries";

export type SourceProductRecSubscription = Omit<Subscription, "intervals"> & {
  intervals: SubscriptionInterval;
};

export type SourceProductRecSubscriptionOption = Omit<
  ProductSubscriptionOption,
  "item" | "quantityLabel"
> & {
  item: Variant;
  quantityLabel: QuantityLabel;
  showSubcriptionPriceDisclaimer: boolean;
};

export type SourceProductRec = Omit<
  Product,
  | "altBBLogo"
  | "optionTypes"
  | "variants"
  | "shopCollections"
  | "subscription"
  | "subscriptionOptions"
  | "shopCard"
> & {
  optionTypes: OptionType[];
  shopCollections: ShopCollection[];
  subscription: SourceProductRecSubscription;
  subscriptionOptions: SourceProductRecSubscriptionOption[];
  shopCard?: DereferencedShopCard;
  variants: DereferencedVariant[];
  bestValueVariant: DereferencedVariant;
};

export const transformProductRecsToShopCards = (
  productRecs: SourceProductRec[] | AlgoliaRecommendProduct[],
  lang: Dialect["lang"],
  region: Dialect["region"],
  trackClickProduct: (product: { product: ProductChord }) => any,
): ShopCardProps[] =>
  productRecs.map((productRec): ShopCardProps => {
    const shopCard = {
      ...toShopCardProps({
        data: productRec,
        lang,
        region,
        props: {
          sourceIndexName: publicShopCollectionProductsIndex.indexName,
        },
      }),
      useVariantPrice,
    };

    return {
      ...shopCard,
      clickTracking: () => trackClickProduct(shopCard.trackShopcardData),
      product: undefined,
      copy: undefined,
      shippingCopy: undefined,
      subscribeProduct: undefined,
      addToCart: undefined,
      useVariantAvailability: undefined,
      createStockRequest: undefined,
    };
  });

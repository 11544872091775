import algoliasearch from "algoliasearch";
import algoliarecommend from "@algolia/recommend";

/**
 * Algolia Search Client
 */
export const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.ALGOLIA_API_KEY,
);

/**
 * Algolia Recommend Client
 */
export const recommendClient = algoliarecommend(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY,
);

/**
 * Algolia Search Client
 */
export const publicSearchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY,
);

/**
 * Algolia Recommend Client
 */
export const publicRecommendClient = algoliarecommend(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY,
);

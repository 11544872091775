// NOTE: This is pulled from Chord's starter repo
// https://github.com/chordcommerce/chord-blue-bottle-coffee-sanity/blob/913f1941c7469a89e1de8c2e7451ad7a20342599/packages/web/src/hooks/components/use-variant-availability.js#L1-L25
// but they said since it is specific to one of their components,
// IT WILL PROBABLY NOT BE INCLUDED IN THEIR FINAL PACKAGED SDK!?!
import useSWR from "swr";
import axios from "axios";

// NOTE: Chord's original code uses CHORD_OMS_API_URL,
// but we need it available in the browser so prepended `NEXT_PUBLIC`
const API_URL = process.env.NEXT_PUBLIC_CHORD_OMS_API_URL || "";

const fetcher = (url) => axios.get(url).then((res) => res.data);

const useVariantAvailability = ({ shouldFetch, sku }) => {
  const baseURL = API_URL.endsWith("/") ? API_URL.slice(0, -1) : API_URL;

  const query = `${baseURL}/api/variants/${sku}/stock_availabilities`;

  // NOTE: shouldFetch is another divergence from the original Chord hook,
  // but it allows us to prevent unnecessary API calls
  // https://swr.vercel.app/docs/conditional-fetching
  const { data, error } = useSWR(() => (shouldFetch ? query : null), fetcher);

  // Start by assuming that the product is available. And if something
  // wrong happened in this hook, worse case scenario, it will be handled
  // by Chord's checkout. (Chord's note)
  return {
    isAvailable: data ? data.in_stock || data.is_backorderable : true,
    isFetchingAvailability: !error && !data,
    error,
    // NOTE: Chord's version does not provide totalOnHand, added for our purposes
    totalOnHand: data ? data.total_on_hand : undefined,
  };
};

export default useVariantAvailability;

import { FooterProps } from "@bluebottlecoffee/design-system/components";
import { Footer as Schema } from "../sanity-schema";
import { toLinkProps } from "./link";

export function toFooterProps(
  data: Schema,
  region: string,
  lang: string,
  onNewsletterFormSubmit: (email) => void,
): FooterProps {
  return {
    heading: data.heading[lang],
    copyright: data.copyright[lang],
    finePrint: data.finePrint.map((l) => toLinkProps(l, region, lang)),
    newsletterFinePrint: toLinkProps(data.newsletterFinePrint, region, lang),
    newsletterForm: {
      description: data.newsletterForm.description[lang],
      label: data.newsletterForm.label[lang],
      onSubmit: onNewsletterFormSubmit,
    },
    footerLists: data.footerLists.map((l) => ({
      heading: l.heading[lang],
      listItems: l.listItems.map((li) => toLinkProps(li, region, lang)),
    })),
  };
}
